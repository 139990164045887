.App {
    text-align: center;
    /* height: 100vh; */
    /* max-height: 100vh; */
    width: 100vw;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
    margin-top: -5rem;
    animation-duration: 3s;
    /* animation-name: App-logo__hides-4-now; */
    animation-name: slideinFromBottom;
  }
  @keyframes App-logo__hides-4-now {
    from {
      margin-left: 300%;
      color: transparent;
/*       z-index: -1; */
      /* width: 300%; */
    }
  
    to {
      margin-left: 0%;
      color: transparent;
      /* z-index: -1; */
      /* width: 100%; */
    }
  }
  
  /* @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  } */
 
  .App-header-dark {
    background-color: #0f0f0f;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-header-light{
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #0f0f0f;
  }
  
  .App-link {
    color: #86ff4e;
  }

  .light{
    color: rgb(0, 174, 255)
  }

  
  /* @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
  .landing__header-nav-btn{
    padding-bottom: 5rem;
  }
  .landing__btn{
    cursor: pointer;
  }
  .landing__btn:hover{
    transform: scale(1.2,1.2);
  }
  .landing__btn:active{
    transform: scale(1.5,1.5);
  }
  .landing__darkMode-btn{
    background-color: #86ff4e !important;

  }
/*   .landing__darkMode-btn:hover{
    background-color: rgb(87, 245, 87) !important;
  } */
  .landing__darkMode-btn:active{
    transform: scale(1.5,1.5);
  }

  .landing__darkMode-btn-light{
    background-color: rgb(0, 174, 255) !important;
  }
/*   .landing__darkMode-btn-light:hover{
    background-color: rgb(98, 195, 240) !important;
  } */
  .landing__darkMode-btn-light:active{
    transform: scale(1.5,1.5);
  }
  .darkmode__icon{
    height: 30px;
    width: 30px;
  }

  .landing__text{
    width: 300px;
    
  }
  .landing__textBlock{
    animation-duration: 3s;
    animation-name: slideinFromBottom;
  }

  
  @keyframes slideinFromRight {
    from {
      margin-left: 100%;
      opacity: 0;
      /* width: 300%; */
    }
  
    to {
      margin-left: 0%;
      opacity: 1;
      /* width: 100%; */
    }
  }
  


  .landing__lang-btns{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    animation-duration: 9s;
    animation-name: slideinFromBottom;
  }
  @keyframes slideinFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}


  