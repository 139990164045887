ul {
    list-style-type: none;
}

.projects__container {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 100vw;
    height: 100vh;
}